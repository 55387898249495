import GridPoint from "@/components/configurator/Editor/lib/Cordinates/GridPoint";
import constants from "./constants";
import store from '../../../../plugins/store/vuex';
import Helpers from "@/components/configurator/Editor/lib/Helpers.js"
import _ from 'lodash';

export default class ElementStore { 
    elements = {};
    overlayedElements = {}; // Elements placed on top of elements
    connectors = {};
    underlayedElements = {}; // Elements placed under the elements

    helpers = new Helpers();

    addElement(gridPosition, currentElement, rotation) {
        /**
         * Augmment element and strip it of unnecessary properties that are not required to free up memory
         * and decuple reference to inventory elements hoping this severs reactivity
         */
        let element = _.cloneDeep(currentElement);
        element = this.augmentAndStripUnnecessaryValues(element, gridPosition, rotation)

        /**
         * Check if element can be dropped
         */
        if (!this.canAddElement(gridPosition, element, rotation)) {
            return [];
        }

        /**
         * Store the element into memory
         */
        this.storeElement(gridPosition, element, rotation);

        /**
         * Check for connectors on this element
         */
        let connectors = this.calculateConnectors(element);
        for (let connector of connectors) {
            this.connectors[connector.gridPosition.toString()] = connector;
        }  

        return [
            element,
            connectors
        ];
    }

    addConnector(gridPosition, currentElement, oldConnector) {
        // No need to find multiple points we are operating under 1 point 
        var connector = _.cloneDeep(constants.EMPTY_CONNECTOR)
        connector.gridPosition = gridPosition

        let [elementNeighbours, overlayedElementNeighbours, underlayedElementNeighbours, elementActingLikeConnectorNeighbours] = this.getConnectorNeighbours(connector);
            
        /**
         * Disticns elements only (those whose gridPosition attribute match are the same element (store postion != grid position on element with the width or height grater than 1))
         * 
         * Also check for accesories acting like elements and add them to the grup
         */
        let distinctElements = this.connectorDistinctNeighbours(elementNeighbours)
        let distinctOverlayedElements = this.connectorDistinctNeighbours(overlayedElementNeighbours)
        let distinctUnderlayedElements = this.connectorDistinctNeighbours(underlayedElementNeighbours)
        let distinctElementsActingLikeConnector = this.connectorDistinctNeighbours(elementActingLikeConnectorNeighbours)
         
        let neighbours = elementNeighbours.concat(overlayedElementNeighbours, distinctUnderlayedElements, elementActingLikeConnectorNeighbours)
        let distinctNeighbourElements = distinctElements.concat(distinctOverlayedElements, distinctUnderlayedElements, distinctElementsActingLikeConnector)
 
        if(distinctNeighbourElements.length <= 1) return null
 
        // Add connectingElement and connectingElementConnections attribute to connector
        connector = this.addElementsAndConnectionsToConnector(connector, neighbours, distinctNeighbourElements)
 
        // Check connecting elements and apply rule trigering attributes
        connector = this.addRuleTriggeringAttributes(connector)
 
        // Add type to connector
        let connectingElementConnectionsLenght = connector.connectingElementConnections.length
 
        // Connector is not required as only 1 point is here 
        if(connectingElementConnectionsLenght == 1) return null
 
        // Map elemnt to item 
        let item = this.productToItemInConnectors(currentElement)
        connector.color = currentElement.variation.color;
        connector.item = item

        // TODO why sku fails here when we use: let item = this.productToItemInConnectors(element) it returs sku: 1 instead of object
        // For now manualy fix it
        connector.item.sku = currentElement.item.variations.reduce((acc, item)=>{
            acc[item.color] = item.sku;
            return acc;
        }, {})
 
        // Add spacers to connector
        this.addSpacersToconnector(connector)  

        // Check  neighbour count based on what kind of connector we are adding
        let canDrop = false
    
        if(currentElement.item.rule == constants.RULESET_CONNECTING_PIN_SHORT || currentElement.item.rule == constants.RULESET_CONNECTING_PIN_LONG) {
            // Do not allow a drop on wrong connections
            if (connectingElementConnectionsLenght == 4) {
                canDrop = true
            } else if (connectingElementConnectionsLenght == 2 && connector.connectingElementConnections.every(item => { return item.middleConnector })) {
                canDrop = true
            } else if (connectingElementConnectionsLenght == 3 && connector.connectingElementConnections.some(item => { return item.middleConnector })) {
                canDrop = true
            }
        }
        
        if(
            currentElement.item.rule == constants.RULESET_SIDE_SCREW || 
            currentElement.item.rule == constants.RULESET_SIDE_SCREW_WITH_NUT_LONG ||
            currentElement.item.rule == constants.RULESET_SIDE_SCREW_290
        ) {      
            if (connectingElementConnectionsLenght == 2 && !connector.connectingElementConnections.every(item => { return item.middleConnector })) {
                canDrop = true
            } else if (connectingElementConnectionsLenght == 3 && !connector.connectingElementConnections.some(item => { return item.middleConnector })) {
                canDrop = true
            }

            // Jebus forgive me (sidescrews are always black)
            connector.color = "black"
        }

        // canDrop pass failed connector cant be droped here return null and ignore
        if(canDrop == false) return null

        // Delete old connector and store new one 
        if (oldConnector) {
            delete this.connectors[gridPosition.toString()];
        }

        this.connectors[connector.gridPosition.toString()] = connector;
        
        return connector;
    }

    removeElement(storedElement) {
        // Keep track of all point affected by this action so we can remove the connectors on them and recalculate them
        let affectedConnectionPoints = []
        
        // First remove all instance from DB so we can recalculate connectors (these are DB storage points not nececery connection points)
        this.getElementsStoragePoints(storedElement.gridPosition, storedElement).forEach(gridPosition => {
            // TODO Mark : when we use this and rotate bathing slide it calculates the wrong points -- why
            //storedElement.item.size.getAreaPoints(storedElement.gridPosition).forEach(gridPosition => { 
            if (this.helpers.isOverlayingElement(storedElement.item.rule)) {
                delete this.overlayedElements[gridPosition.toString()];
            } else {
                delete this.elements[gridPosition.toString()];
            }
        })

        // Get element connection points and store them
        affectedConnectionPoints = [].concat(this.getElementConnectionPoints(storedElement.gridPosition, storedElement))
        
        /**
         * Check if element we are deleting has any overlaying element on his connection and remove those as well & fix their connectors
         * Only do the check if element is not a overlaying element
         */    
        let removedOverlayedElements = [];   
        if (!this.helpers.isOverlayingElement(storedElement.item.rule)) {
            this.getElementConnectionPoints(storedElement.gridPosition, storedElement).forEach(gridPosition => {
                // Check element connecting point for overlaying element
                if(this.overlayedElements[gridPosition.toString()]) {
                    let overlayedElement = _.cloneDeep(this.overlayedElements[gridPosition.toString()])
                    removedOverlayedElements.push(overlayedElement) // For every removed element we have to redraw connectors

                    // Store removed overlayed element connecting points
                    affectedConnectionPoints = affectedConnectionPoints.concat(this.getElementConnectionPoints(overlayedElement.gridPosition, overlayedElement))

                    // Remove overlayed element from DB using his area points
                    for (const areaPoint of overlayedElement.size.getAreaPoints(overlayedElement.gridPosition)) {
                        delete this.overlayedElements[areaPoint.toString()]
                    }
                }
            })
        }

        /**
         * Check if element we are deleting has a underlaying element on his connection points and remove it 
         * if it does as it will change the connectors there so it has to be removed and connectiong points of 
         * underlaying elements must recalculate their connectors
         */
        let removedUnderlayingElements = [];
        this.getElementConnectionPoints(storedElement.gridPosition, storedElement).forEach(gridPosition => {
            // Check element connecting point for underlaying element
            if(this.underlayedElements[gridPosition.toString()]) {
                // Underlaying element found remove his positions from database
                let underlayedElement = _.cloneDeep(this.underlayedElements[gridPosition.toString()])
                removedUnderlayingElements.push(underlayedElement)

                for (const areaPoint of underlayedElement.size.getAreaPoints(underlayedElement.gridPosition)) {
                    delete this.underlayedElements[areaPoint.toString()]
                }

                // Store removed underlayed element connecting points
                affectedConnectionPoints = affectedConnectionPoints.concat(this.getElementConnectionPoints(underlayedElement.gridPosition, underlayedElement))
            }
        })

        /**
         * Check if element we are deleting has a accessorie on his connection points and remove it 
         * if it does as it might break the rules of a accessorie when element is removed and its impossible to 
         * check all variations of rules
         */
         let removedElements = [];
         this.getElementConnectionPoints(storedElement.gridPosition, storedElement).forEach(gridPosition => {
            // Check element connecting point for underlaying element
            if(this.elements[gridPosition.toString()] && this.helpers.isAccessory(this.elements[gridPosition.toString()].item.rule)) {
                // Element element found remove his positions from database
                let element = _.cloneDeep(this.elements[gridPosition.toString()])
                removedElements.push(element)

                for (const areaPoint of element.size.getAreaPoints(element.gridPosition)) {
                    delete this.elements[areaPoint.toString()]
                }

                // Store removed element connecting points
                affectedConnectionPoints = affectedConnectionPoints.concat(this.getElementConnectionPoints(element.gridPosition, element))
            }
        })

        /**
         * Distinct the points so we dont remove/draw on the same point 2x if we do
         * pixi will make 2 pictures with same id but only removes 1 when calling the id on delete so 1 is "permanent"
         */
        let distinctPoints = [...new Map(affectedConnectionPoints.map(connection => [connection.toString(), connection])).values()];
        
        // Remove connector on all affected points and then recalculate them
        let removedConnectors = [];
        let redrawedConnectors = [];
        distinctPoints.forEach(gridPosition => {
            let existingConnector = this.connectors[gridPosition.toString()];
            
            // Create a solid copy of the current value
            if(existingConnector) removedConnectors.push(_.cloneDeep(existingConnector))
            
            // Remove from database
            delete this.connectors[gridPosition.toString()]
            
            // Recalculate the connector and store the value
            let redrawedConnector = this.recalculateConnector(gridPosition)
            if(redrawedConnector) {
                redrawedConnectors.push(redrawedConnector)
                this.connectors[redrawedConnector.gridPosition.toString()] = redrawedConnector;
            }
        })

        // [redrawedConnectors, removedConnectors, removedOverlayedElements, removedUnderlayingElements] 
        return [redrawedConnectors, removedConnectors, removedOverlayedElements, removedUnderlayingElements, removedElements]
    }

    removeConnector(connector) {
        let removedConnectors = [];
        let redrawedConnectors = [];

        // Store removed connecotr for drawing removal
        removedConnectors.push(connector)
        // Remove connector user clicked on
        delete this.connectors[connector.gridPosition.toString()];

        // If element exists on current coordinates redraw connector back if required
        let storedElement = this.getElement(connector.gridPosition);
        if (!storedElement) {
            return [redrawedConnectors, removedConnectors];
        }

        let redrawedConnector = this.recalculateConnector(connector.gridPosition)

        if(redrawedConnector) {
            delete this.connectors[redrawedConnector.gridPosition.toString()];
            this.connectors[redrawedConnector.gridPosition.toString()] = redrawedConnector;

            redrawedConnectors.push(redrawedConnector)
        }
        
        return [redrawedConnectors, removedConnectors];
    }

    removeAllElements() {
        this.elements = {};
        this.overlayedElements = {};
        this.underlayedElements = {};
        this.connectors = {};
    }

    /**
     * 
     * @param {GridPoint} gridPosition 
     * @returns {object|null}
     * 
     * Return single instance of element
     */
    getElement(gridPosition) {
        return this.elements[gridPosition.toString()];
    }

    /**
     * 
     * @param {GridPoint} gridPosition 
     * @returns {object|null}
     * 
     * Return single instance of overlayed element
     */
    getOverlayedElement(gridPosition) {
        return this.overlayedElements[gridPosition.toString()]
    }

    /**
     * 
     * @param {GridPoint} gridPosition 
     * @returns {object|null}
     * 
     * Return single instance of underlayed
     */
    getUnderlayedElement(gridPosition) {
        return this.underlayedElements[gridPosition.toString()]
    }

    /**
     * 
     * @param {GridPoint} gridPosition 
     * @returns {object|null}
     * 
     * Return single instance of connector
     */
    getConnector(gridPosition) {
        return this.connectors[gridPosition.toString()];
    }

    getAll() {
        return {
            "elements": this.elements, 
            "connectors": this.connectors,
            "overlayedElements": this.overlayedElements,
            "underlayedElements": this.underlayedElements
        }  
    }

    storeElement(gridPosition, element, rotation) {
        /**
         * Some elements actualy act like a connector so we will move them to connectors 
         * 
         * Here we only make a note of their existance in the DB so the calculateConnectors function finds them and adds them to calculations
         * 
         * #cringe
         */
        if(this.helpers.isElementActingLikeConnector(element.item.rule)) {
            let connector = _.cloneDeep(constants.EMPTY_CONNECTOR)

            // Setup whatever we need as a placeholder this is just a temporary object it will be overwritten when calculateConnectors is called in the next step
            connector.gridPosition = element.gridPosition;
            connector.isAccessory = true;
            let item = this.productToItemInConnectors(element)

            item.sku = element.item.variations.reduce((acc, item)=>{
                acc[item.color] = item.sku;
                return acc;
            }, {})

            connector.item = item
            connector.availableConnections = constants.ELEMENT_CONNECTIONS[element.item.rule];
            this.connectors[connector.gridPosition.toString()] = connector;

            return 
        }

        /**
         * Corner bumpers are L shaped elements so we are super fucked if we do X*Y logic as it will
         * oveeride other elements in DB so we return points based on the element rule + position
         * 
         * #cringe
         */
        if(this.helpers.isSideBumperCornerOuter(element.item.rule) || this.helpers.isSideBumperCornerInner(element.item.rule)) {
            this.getCornerElementStoragePoints(gridPosition, element.item.rule, rotation).forEach(point => {
                this.elements[point.toString()] = { ...element };
            })  
        }

        let size = element.size;
        for(let offsetX = 0; offsetX < size.width; offsetX++) {
            for(let offsetY = 0; offsetY < size.height; offsetY++) {
                let point = new GridPoint(gridPosition.x + offsetX, gridPosition.y + offsetY, gridPosition.level);
                if (this.helpers.isLadder(element.item.rule) || this.helpers.isBathingSlide(element.item.rule) || this.helpers.isConnectingPinWithSteel(element.item.rule) || this.helpers.isFence(element.item.rule)) {
                    this.overlayedElements[point.toString()] = { ...element };    
                    continue;
                }

                if (this.helpers.isMooringDevice(element.item.rule)) {
                    this.underlayedElements[point.toString()] = { ...element };    
                    continue;
                }

                this.elements[point.toString()] = { ...element };
            }
        }
    }

    // This is the most cancerous thing ive done in my life
    getCornerElementStoragePoints(gridPosition, rule, rotation) {
        let points = [];

        // gridPosition is the point described in the photo in public folder
        if(rule == constants.RULESET_SIDE_BUMPER_CORNER_OUTER_0) {
            points.push(gridPosition)
        }

        if(rule == constants.RULESET_SIDE_BUMPER_CORNER_OUTER) {
            points.push(gridPosition) // central position

            switch (rotation) {
                case 0: { // left
                    points.push(gridPosition.move(0, -1))
                    points.push(gridPosition.move(-1, -1))
                    break;
                }
                case 1: { // top
                    points.push(gridPosition.move(0, -1))
                    points.push(gridPosition.move(-1, 0))
                    break;
                }
                case 2: { // right
                    points.push(gridPosition.move(0, -1))
                    points.push(gridPosition.move(1, 0))
                    break;
                }
                case 3: { // down
                    points.push(gridPosition.move(0, -1))
                    points.push(gridPosition.move(1, -1))
                    break;
                }
            }
        }

        if(rule == constants.RULESET_SIDE_BUMPER_CORNER_OUTER_3) {
            points.push(gridPosition) // central position

            switch (rotation) {
                case 0: { // left
                    points.push(gridPosition.move(0, 1))
                    points.push(gridPosition.move(0, -1)) // diagonal to corner square
                    points.push(gridPosition.move(-1, -1))
                    points.push(gridPosition.move(-2, -1))
                    break;
                }
                case 1: { // top
                    points.push(gridPosition.move(0, -1))
                    points.push(gridPosition.move(0, -2))
                    points.push(gridPosition.move(-1, 0))
                    points.push(gridPosition.move(-2, 0))
                    break;
                }
                case 2: { // right
                    points.push(gridPosition.move(0, -1))
                    points.push(gridPosition.move(0, -2))
                    points.push(gridPosition.move(1, 0))
                    points.push(gridPosition.move(2, 0))
                    break;
                }
                case 3: { // down
                    points.push(gridPosition.move(0, -1)) // diagonal to corner square
                    points.push(gridPosition.move(0, 1))
                    points.push(gridPosition.move(1, -1))
                    points.push(gridPosition.move(2, -1))
                    break;
                }
            }
        }

        if(rule == constants.RULESET_SIDE_BUMPER_CORNER_OUTER_4) {
            points.push(gridPosition) // central position

            switch (rotation) {
                case 0: { // left
                    points.push(gridPosition.move(0, 1))
                    points.push(gridPosition.move(0, 2))
                    points.push(gridPosition.move(0, -1)) // diagonal to corner square
                    points.push(gridPosition.move(-1, -1))
                    points.push(gridPosition.move(-2, -1))
                    points.push(gridPosition.move(-3, -1))
                    break;
                }
                case 1: { // top
                    points.push(gridPosition.move(0, -1))
                    points.push(gridPosition.move(0, -2))
                    points.push(gridPosition.move(0, -3))
                    points.push(gridPosition.move(-1, 0))
                    points.push(gridPosition.move(-2, 0))
                    points.push(gridPosition.move(-3, 0))
                    break;
                }
                case 2: { // right
                    points.push(gridPosition.move(0, -1))
                    points.push(gridPosition.move(0, -2))
                    points.push(gridPosition.move(0, -3))
                    points.push(gridPosition.move(1, 0))
                    points.push(gridPosition.move(2, 0))
                    points.push(gridPosition.move(3, 0))
                    break;
                }
                case 3: { // down
                    points.push(gridPosition.move(0, -1)) // diagonal to corner square
                    points.push(gridPosition.move(0, 1))
                    points.push(gridPosition.move(0, 2))
                    points.push(gridPosition.move(1, -1))
                    points.push(gridPosition.move(2, -1))
                    points.push(gridPosition.move(3, -1))
                    break;
                }
            }
        }

        if(rule == constants.RULESET_SIDE_BUMPER_CORNER_INNER) {
            points.push(gridPosition) // central position
        }

        if(rule == constants.RULESET_SIDE_BUMPER_CORNER_INNER_3) {
            points.push(gridPosition) // central position

            switch (rotation) {
                case 0: { // left
                    points.push(gridPosition.move(0, 1))
                    //points.push(gridPosition.move(0, 2))
                    points.push(gridPosition.move(-1, 0))
                    //points.push(gridPosition.move(-2, 0))
                    break;
                }
                case 1: { // top
                    points.push(gridPosition.move(0, -1))
                    //points.push(gridPosition.move(0, -2))
                    points.push(gridPosition.move(-1, 0))
                    //points.push(gridPosition.move(-2, 0))
                    break;
                }
                case 2: { // right
                    points.push(gridPosition.move(0, -1))
                    //points.push(gridPosition.move(0, -2))
                    points.push(gridPosition.move(1, 0))
                    //points.push(gridPosition.move(2, 0))
                    break;
                }
                case 3: { // down
                    points.push(gridPosition.move(1, 0))
                    //points.push(gridPosition.move(2, 0))
                    points.push(gridPosition.move(0, 1))
                    //points.push(gridPosition.move(0, 2))
                    break;
                }
            }
        }

        if(rule == constants.RULESET_SIDE_BUMPER_CORNER_INNER_4) {
            points.push(gridPosition) // central position

            switch (rotation) {
                case 0: { // left
                    points.push(gridPosition.move(0, 1))
                    points.push(gridPosition.move(0, 2))
                    //points.push(gridPosition.move(0, 3))
                    points.push(gridPosition.move(-1, 0))
                    points.push(gridPosition.move(-2, 0))
                    //points.push(gridPosition.move(-3, 0))
                    break;
                }
                case 1: { // top
                    points.push(gridPosition.move(0, -1))
                    points.push(gridPosition.move(0, -2))
                    //points.push(gridPosition.move(0, -3))
                    points.push(gridPosition.move(-1, 0))
                    points.push(gridPosition.move(-2, 0))
                    //points.push(gridPosition.move(-3, 0))
                    break;
                }
                case 2: { // right
                    points.push(gridPosition.move(0, -1))
                    points.push(gridPosition.move(0, -2))
                    //points.push(gridPosition.move(0, -3))
                    points.push(gridPosition.move(1, 0))
                    points.push(gridPosition.move(2, 0))
                    //points.push(gridPosition.move(3, 0))
                    break;
                }
                case 3: { // down
                    points.push(gridPosition.move(1, 0))
                    points.push(gridPosition.move(2, 0))
                    //points.push(gridPosition.move(3, 0))
                    points.push(gridPosition.move(0, 1))
                    points.push(gridPosition.move(0, 2))
                    //points.push(gridPosition.move(0, 3))
                    break;
                }
            }
        }

        return points
    }

    canAddElement(gridPosition, newElement, rotation) {
        if(this.helpers.isLadder(newElement.item.rule)) {
            return this.canDropLadder(gridPosition, newElement, rotation)  
        }

        if(this.helpers.isBasicElement(newElement.item.rule)) {
            return this.canDropBasicElement(gridPosition, newElement)
        }

        if(this.helpers.isSideBumper(newElement.item.rule)) {
            return this.canDropSideBumper(gridPosition, newElement, rotation)
        }

        if(this.helpers.isOuterScrewJoint(newElement.item.rule)) {
            return this.canDropOutsideScrewJoint(gridPosition)
        }

        if(this.helpers.isInnerScrewJoint(newElement.item.rule)) {
            return this.canDropInnerScrewJoint(gridPosition)
        }

        if(this.helpers.isSpecialScrewJoint(newElement.item.rule)) {
            return this.canDropSpecialScrewJoint(gridPosition)
        }

        if(this.helpers.isConnectingPinWithSteel(newElement.item.rule)) {
            return this.canDropConnectingPinWithSteel(gridPosition)
        }

        if(this.helpers.isMooringDevice(newElement.item.rule)) {
            return this.canDropMooringDevice(gridPosition, newElement, rotation)
        }

        if(this.helpers.isFence(newElement.item.rule)) {
            return this.canDropFence(gridPosition, newElement, rotation)
        }

        if(this.helpers.isPileCageOuter(newElement.item.rule)) {
            return this.canDropPileCageOuter(gridPosition, newElement, rotation)
        }

        if(this.helpers.isPileCageInner(newElement.item.rule)) {
            return this.canDropPileCageInner(gridPosition, newElement, rotation)
        }

        if(this.helpers.isWinch(newElement.item.rule)) {
            return this.canDropWinch(gridPosition, newElement, rotation)
        }

        if(this.helpers.isBathingSlide(newElement.item.rule)) {
            return this.canBathingSlide(gridPosition, newElement, rotation)
        }

        if(this.helpers.isSideBumperCornerOuter(newElement.item.rule)) {
            return this.canDropSideBumperCornerOuter(gridPosition, newElement, rotation)
        }

        if(this.helpers.isSideBumperCornerInner(newElement.item.rule)) {
            return this.canDropSideBumperCornerInner(gridPosition, newElement, rotation)
        }  
    }

    canDropBasicElement(gridPosition, newElement) {
        let canDrop = true
        /**
         * Basic element can be placed if:
         *  - no other element exist on the current clicked location 
         *  - no cleat exist on its connecting points
         */

        // No other element is on the coordinates you are trying to drop
        newElement.size.getAreaPoints(gridPosition).some((point) => {
            if (this.getElement(point)) {
                canDrop = false;
                return canDrop
            }
        });

        return canDrop;
    }

    canDropLadder(gridPosition, newElement, rotation) {
        let canDrop = true
        /**
         * Ladder element can be placed if:
         *  - basic element exist on the current clicked location 
         *  - all connecting points are a connector
         *  - depending on rotation it must be on the side TODO (you can drop ladder even if side bumper exist right now)
         */
        let element = this.getElement(newElement.gridPosition)
        if (element && this.helpers.isBasicElement(element.item.rule)) {
            canDrop = true;
        } else {
            canDrop = false
        }

        // Ladder cant connect if there are some accesories acting as connector on its points
        let connectingPoints = this.getElementConnectionPoints(gridPosition, newElement)
        connectingPoints.forEach((connectingPoint) => {
            let connector = this.getConnector(connectingPoint)

            if(connector && connector.item.type != "connectors") {
                canDrop = false
                return canDrop
            } 
        })

        // Check space behind ladder must be empty
        let sideElement = this.getElement(gridPosition.getLadderCheckPosition(rotation))
        
        if(sideElement) {
            canDrop = false;
            return canDrop
        }

        // Depending on the rotation there is no overlayed element to the side of it
        if(rotation == 0 || rotation == 2) {
            let checkPoints = [gridPosition.move(0, -1), gridPosition.move(0, 1)]

            checkPoints.forEach((connectingPoint) => {
                let overlayedElement = this.getOverlayedElement(connectingPoint)
        
                if(overlayedElement) {
                    canDrop = false;
                    return canDrop
                }
            });
        } else {
            let checkPoints = [gridPosition.move(-1, 0), gridPosition.move(1, 0)]

            checkPoints.forEach((connectingPoint) => {
                let overlayedElement = this.getOverlayedElement(connectingPoint)
        
                if(overlayedElement) {
                    canDrop = false;
                    return canDrop
                }
            });
        }

        return canDrop;
    }

    canDropSideBumper(gridPosition, newElement, rotation) {
        let canDrop = true
        /**
         * Side bumber can be placed if:
         *  -no other element exist on the current clicked location or to the side of it
         *  -all points it is trying to connect to exist and have at least 1 element in the neigbhour pool
         *  -point it is trying to connect do not contain side bumper
         *  -points are on the outside of structure
         */
        let clickPointAndNeighbours = gridPosition.getSidebumperNeighbourSquares(rotation, newElement.size.width, newElement.size.height)
        clickPointAndNeighbours.push(gridPosition)

        // Check that no other element exist on the current location or to the side of it
        if(this.checkForElements(clickPointAndNeighbours)) {
            canDrop = false
            return canDrop
        }

        let connectingPoints = []
        for (let [, availableConnection] of Object.entries(newElement.availableConnections)) {
            connectingPoints.push(gridPosition.move(availableConnection.position.x, availableConnection.position.y))
        }
        // Connecting points are point moved by lenght of the element 
        connectingPoints.forEach((connectingPoint) => {
            // No side bumper must exist on the connecting point
            let element = this.getElement(connectingPoint)
            if(element && element.item.group == "side_bumpers") {
                canDrop = false
                return canDrop
            }

            // No other accessorie exists on connector
            let connector = this.getConnector(connectingPoint)
            if(connector) {
                connector.connectingElements.forEach(element => {
                    if(element.item.type == 'accessories') {
                        canDrop = false
                        return canDrop
                    }
                })
            }
            
            // Check points around the connection point if element is there (required for connecting)
            let neighbours = connectingPoint.getSidebumperNeighbourSquaresForConnection(rotation)
            let elementsFound = 0
            // Neighbours togother must have at least 1 element
            neighbours.forEach((point) => {
                element = this.getElement(point)
                if(element && element.item.group != "side_bumpers") elementsFound++
            })

            // No elements found for this point to connect to so its impossible to connect
            if(elementsFound == 0) {
                canDrop = false
                return canDrop
            }
            elementsFound = 0; // Reset counter
        })

        return canDrop
    }

    canDropOutsideScrewJoint(gridPosition) {
        let canDrop = true
        /**
         * Outside screw joint can be placed if:
         *  -no other cleat exist on current position
         *  -point is on the edge (only allow if less than 4 elements are connected)
         */

        // Check that no other outer screw joint element exist on the current location
        let connector = this.getConnector(gridPosition)
        if(connector && this.helpers.isOuterScrewJoint(connector.item.rule)) {
            canDrop = false
            return canDrop
        }

        // Point is on the edge the neighbours are under 4 in lenght and element must be a primary item
        let neigbours = 0;
        for (let neighbourPoint of gridPosition.getNeighbourSquaresAroundPoint()) {            
            let element = this.getElement(neighbourPoint);
            if(element) neigbours ++;
        }

        if(neigbours == 0 || neigbours == 4) {
            canDrop = false
            return canDrop
        }

        return canDrop
    }

    canDropInnerScrewJoint(gridPosition) {
        let canDrop = true
        /**
         * Inner screw joint can be placed if:
         *  -no other inner screw joint exist on current position
         *  -point is in the middle of elements (4 neighbours)
         */

        // Check that no other inner screw joint element exist on the current location
        let connector = this.getConnector(gridPosition)
        if(connector && this.helpers.isInnerScrewJoint(connector.item.rule)) {
            canDrop = false
            return canDrop
        }

        // Point is in the center of 4 elements
        let neigbours = 0;
        for (let neighbourPoint of gridPosition.getNeighbourSquaresAroundPoint()) {            
            let element = this.getElement(neighbourPoint);
            if(element) neigbours ++;
        }

        if(neigbours != 4) {
            canDrop = false
            return canDrop
        }

        return canDrop
    }

    canDropSpecialScrewJoint(gridPosition) {
        let canDrop = true
        /**
         * Special screw joint can be placed if:
         *  -no other inner|outer screw joint exist on current position
         *  -point must have 1 neighbour with overlaping connection points
         */

        // Check that no other inner|outer|special screw joint exist on current position
        let connector = this.getConnector(gridPosition)
        if(connector && this.helpers.isElementActingLikeConnector(connector.item.rule)) {
            canDrop = false
            return canDrop
        }

        // Point must have 1 neighbour with overlaping connection points

        return canDrop
    }

    canDropConnectingPinWithSteel(gridPosition) {
        let canDrop = true
        /**
         * Connecting pin with steel can be placed if:
         *  -no other connecting pin with steel exists on the location
         *  -point is in the middle of elements (4 neighbours)
         */

        // Check that no other connecting pin with steel element exist on the current location
        let element = this.getElement(gridPosition)
        if(element && this.helpers.isConnectingPinWithSteel(element.item.rule)) {
            canDrop = false
            return canDrop
        }

        // Point is on the edge the neighbours are under 4 in lenght and element must be a primary item
        let neigbours = 0;
        for (let neighbourPoint of gridPosition.getNeighbourSquaresAroundPoint()) {            
            let element = this.getElement(neighbourPoint);
            if(element) neigbours ++;
        }

        if(neigbours != 4) {
            canDrop = false
            return canDrop
        }

        return canDrop
    }

    canDropMooringDevice(gridPosition, newElement) {
        let canDrop = true
        /**
         * Mooring device be placed if:
         *  -no other mooring device exists on the location
         *  -point is in the middle of elements (4 neighbours)
         */

        // Check that no other mooring device element exist on the location we want to drop to
        let storagePoints = this.getElementsStoragePoints(gridPosition, newElement)
        storagePoints.forEach(storagePoint => {

            if(this.underlayedElements[storagePoint.toString()]) {
                canDrop = false
                return canDrop
            }
        })
 
        // Points is on the edge the neighbours are under 4 in lenght and element must be a primary item
        let connectingPoints = this.getElementConnectionPoints(gridPosition, newElement)
        
        connectingPoints.forEach((connectingPoint) => {
            let neigbours = 0;

            for (let neighbourPoint of connectingPoint.getNeighbourSquaresAroundPoint()) {       
   
                let element = this.getElement(neighbourPoint);
                if(element) neigbours ++;
            }

            if(neigbours != 4) {
                canDrop = false
                return canDrop
            }
        })
        
        return canDrop
    }

    canDropFence(gridPosition, newElement, rotation) {
        let canDrop = true
        /**
         * Fence can be placed if:
         *  -no other element exists on the location
         *  -all connectingPoints contain at least 1 neighbour (fence cant be placed in the middle of air)
         *  -all connectingPoints are free of overlaying elements
         */

        // Check that no other fence element exist on the location we want to drop to (no overlaping verticaly or horizontaly)
        let storagePoints = this.getElementsStoragePoints(gridPosition, newElement)
        
        storagePoints.forEach(storagePoint => {
            let overlayedElement = this.overlayedElements[storagePoint.toString()]
            if(overlayedElement) {
                canDrop = false
                return canDrop
            }
        })

        let connectingPoints = this.getElementConnectionPoints(gridPosition, newElement)
        
        // All connectingPoints contain at least 1 neighbour (fence cant be placed in the middle of air)
        connectingPoints.forEach((connectingPoint) => {
            let neigbours = 0;

            for (let neighbourPoint of connectingPoint.getNeighbourSquaresAroundPoint()) {       
   
                let element = this.getElement(neighbourPoint);
                if(element) neigbours ++;
            }

            if(neigbours < 1) {
                canDrop = false
                return canDrop
            }

            // Check that connector has connecting pin here 
            let connector = this.connectors[connectingPoint.toString()]
            if(connector && connector.item.type != 'connectors') {
                canDrop = false
                return canDrop
            }
        })

        // If fence is a corner stanchion check that we can only drop it in the corner
        // Corner means only 1 neighbour or 3 (when making staircase like structure)
        // TODO Staircase structure is buggy
        if(this.helpers.isCornerFence(newElement.item.rule)) {
            let neigbours = 0;
            const rightAmountOfNeighbours = [1,3]

            
            
            //Neighbour points depend on rotation
            let neigbhourPoints = []

            switch (rotation) {
                case 0: { // left
                    neigbhourPoints = gridPosition.getNeighbourSquaresAroundPoint()
                    break;
                }
                case 1: { // top
                    neigbhourPoints = gridPosition.move(1,0).getNeighbourSquaresAroundPoint()
                    break;
                }
                case 2: { // right
                    neigbhourPoints = gridPosition.move(1,1).getNeighbourSquaresAroundPoint()
                    break;
                }
                case 3: { // down
                    neigbhourPoints = gridPosition.move(0,1).getNeighbourSquaresAroundPoint()
                    break;
                }
            }
            
            for (let neighbourPoint of neigbhourPoints) {       
   
                let element = this.getElement(neighbourPoint);
                if(element) neigbours ++;
            }

            if(!rightAmountOfNeighbours.includes(neigbours)) {
                canDrop = false
                return canDrop
            }
        }

        return canDrop
    }

    canDropPileCageOuter(gridPosition, newElement, rotation) {
        let canDrop = true
        /**
         * Pile cage can be placed if:
         *  -no other element exist on the current clicked location
         *  -all points it is trying to connect to exist and have at least 1 element in the neigbhour pool
         *  -points are on the outside of structure
         *  -same as sidebumpers pile cages cant overlap
         */

        // Check that no other element exist on the location we want to drop to (no overlaping verticaly or horizontaly)
        let storagePoints = this.getElementsStoragePoints(gridPosition, newElement)
        //Every pile cage must have no elemnts up|down / left|right to prevent overlaping
        let pileCageNeighbours = [];

        // pile_cage_max_50_outer does not check for neighbours as it connect to the 1 pin only
        if(newElement.item.rule != constants.RULESET_PILE_CAGE_MAX_50_OUTER) {
            pileCageNeighbours = gridPosition.getPileCageEdgeNeighbours(rotation, newElement.size.width, newElement.size.height, newElement.item.rule)
        }
        
        let allPoints = storagePoints.concat(pileCageNeighbours)

        // Check that no other element exist on the current save locations or to the side of it
        if(this.checkForElements(allPoints)) {
            canDrop = false
            return canDrop
        }

        // Check each connection point for 1 neighbour with overlaping connection point (cant be placed in the air)
        let connectionPoints = this.getElementConnectionPoints(gridPosition, newElement)

        connectionPoints.forEach(connectionPoint => {
            // No other element must exist on its connecting point 
            let element = this.getElement(connectionPoint)

            if(element && !this.helpers.isBasicElement(element.item.rule)) {
                canDrop = false
                return canDrop
            }

            let neigbhours = this.getPositionDistinctNeighboursWithOverlapingConnectionPoints(connectionPoint)

            if(neigbhours.length == 0) {
                canDrop = false
                return canDrop
            }
        })

        return canDrop
    }

    canDropPileCageInner(gridPosition, newElement) {
        let canDrop = true
        /**
         * Pile cage can be placed if:
         *  -no other element exist on the current clicked location
         *  -all points it is trying to connect to exist and have at least 1 element in the neigbhour pool
         */

        // Check that no other element exist on the location we want to drop to (no overlaping verticaly or horizontaly)
        let storagePoints = this.getElementsStoragePoints(gridPosition, newElement)

        // Check that no other element exist on the current save locations or to the side of it
        if(this.checkForElements(storagePoints)) {
            canDrop = false
            return canDrop
        }

        // Check each connection point for 1 neighbour with overlaping connection point (cant be placed in the air)
        let connectionPoints = this.getElementConnectionPoints(gridPosition, newElement)

        connectionPoints.forEach(connectionPoint => {
            let neigbhours = this.getPositionDistinctNeighboursWithOverlapingConnectionPoints(connectionPoint)

            if(neigbhours.length == 0) {
                canDrop = false
                return canDrop
            }
        })

        return canDrop
    }

    canDropWinch(gridPosition) {
        let canDrop = true
        /**
         * Winch can be placed if:
         *  -no other winch  exist on current position
         *  -point is in the middle of elements (4 neighbours)
         */

        // Check that no other inner screw joint element exist on the current location
        let connector = this.getConnector(gridPosition)
        if(connector && this.helpers.isWinch(connector.item.rule)) {
            canDrop = false
            return canDrop
        }

        // Point is in the center of 4 elements
        let neigbours = 0;
        for (let neighbourPoint of gridPosition.getNeighbourSquaresAroundPoint()) {            
            let element = this.getElement(neighbourPoint);
            if(element) neigbours ++;
        }

        if(neigbours != 4) {
            canDrop = false
            return canDrop
        }

        return canDrop
    }

    canBathingSlide(gridPosition, newElement)  {
        let canDrop = true
        /**
         * Bathing slide can be placed if:
         *  -no other overlaying element exist on storage postions
         *  -all connecting points are in the middleof elements (4 neighbours)
         */

        // No other overlaying element must exist on slides storage points
        this.getElementsStoragePoints(gridPosition, newElement).forEach(storagePoint => {
            if(this.getOverlayedElement(storagePoint)) {
                canDrop = false
                return canDrop
            }
        })

        // All connecting point must have connecting pin
        this.getElementConnectionPoints(gridPosition, newElement).forEach(connectionPoint => {
            let existingConnector = this.getConnector(connectionPoint)
            if(!existingConnector) {
                canDrop = false
                return canDrop
            } else if(existingConnector.item.rule != constants.RULESET_CONNECTING_PIN_LONG && existingConnector.item.rule != constants.RULESET_CONNECTING_PIN_SHORT) {
                canDrop = false
                return canDrop
            }
        })

        return canDrop
    }

    canDropSideBumperCornerOuter(gridPosition, newElement, rotation)  {
        let canDrop = true
        /**
         * Corner side bumper can be placed if:
         *  -no other element exists on the position
         *  -its in the outer corner of build
         */

        // No other element must exist on storage points
        this.getElementsStoragePoints(gridPosition, newElement).forEach(storagePoint => {
            if(this.getElement(storagePoint)) {
                canDrop = false
                return canDrop
            }
        })

        //When we chech for side corner we check for number of primary elements on specific point
        let neighbours = 0;
        gridPosition.getOutsideCornerSidebumperNeighbourSquares(rotation).forEach(point => {
            let element = this.getElement(point)

            if(element && this.helpers.isBasicElement(element.item.rule)) {
                neighbours++
            }
        })

        if(neighbours != 1) {
            canDrop = false
            return canDrop
        }

        return canDrop
    }

    canDropSideBumperCornerInner(gridPosition, newElement, rotation)  {
        let canDrop = true
        /**
         * Corner side bumper can be placed if:
         *  -no other element exists on the position
         *  -its in the inner corner of build
         */

        // No other element must exist on storage points
        this.getElementsStoragePoints(gridPosition, newElement).forEach(storagePoint => {
            if(this.getElement(storagePoint)) {
                canDrop = false
                return canDrop
            }
        })

        //When we chech for side corner we check for number of primary elements on specific point
        let primaryPoint = gridPosition.getInsideCornerSidebumperPrimaryConnectionPoint(rotation);
        
        // Get connector on primary point. If it has 3 primary items this is a corner
        let connector = this.getConnector(primaryPoint)

        // No connector on the primary point so refuse drop
        if(!connector) {
            canDrop = false
            return canDrop
        }

        let primaryItems = [];
        primaryItems = connector.connectingElements.filter((element) => {
            if(element.item.group == constants.BASE_ELEMENT) return element
        })

        if(primaryItems.length != 3) {
            canDrop = false
            return canDrop
        }

        return canDrop
    }

    /**
     * 
     * @param {GridPoint} gridPosition 
     * @param {*} element 
     * @return {array}
     * 
     * Return all the points element will reside on (stored in the DB) based of element width and height relative to provided gridPosition
     */
    getElementsStoragePoints(gridPosition, element) {
        let size = element.size;
        let points = [];

        // L shaped elements are special cases
        if(this.helpers.isSideBumperCornerOuter(element.item.rule) || this.helpers.isSideBumperCornerInner(element.item.rule)) {
            return this.getCornerElementStoragePoints(element.gridPosition, element.item.rule, element.rotation)
        }

        for(let offsetX = 0; offsetX < size.width; offsetX++) {
            for(let offsetY = 0; offsetY < size.height; offsetY++) {
                let point = new GridPoint(gridPosition.x + offsetX, gridPosition.y + offsetY, gridPosition.level);
                points.push(point)
            }
        }

        return points
    }

    /**
     * 
     * @param {GridPoint} gridPosition 
     * @param {*} element 
     * @returns {array}
     * 
     * Return all points element will connect to (interaction with connectors) based of element availableConnections relative to provided gridPosition
     */
    getElementConnectionPoints(gridPosition, element) {
        let points = [];

        for (let [, availableConnection] of Object.entries(element.availableConnections)) {
            points.push(gridPosition.move(availableConnection.position.x, availableConnection.position.y))
        }

        return points
    }

    /**
     * 
     * @param {object} gridPosition 
     * @returns {object|null}
     * 
     * Recaluclates connector on a specific grid point
     * 
     * Returns null if this point is void of connector
     */
    recalculateConnector(gridPosition) {
        // No need to find multiple points we are operating under 1 point 
        var connector = _.cloneDeep(constants.EMPTY_CONNECTOR)
        connector.gridPosition = gridPosition

        let [elementNeighbours, overlayedElementNeighbours, underlayedElementNeighbours, elementActingLikeConnectorNeighbours] = this.getConnectorNeighbours(connector);
            
        /**
         * Disticns elements only (those whose gridPosition attribute match are the same element (store postion != grid position on element with the width or height grater than 1))
         * 
         * Also check for accesories acting like elements and add them to the grup
         */
        let distinctElements = this.connectorDistinctNeighbours(elementNeighbours)
        let distinctOverlayedElements = this.connectorDistinctNeighbours(overlayedElementNeighbours)
        let distinctUnderlayedElements = this.connectorDistinctNeighbours(underlayedElementNeighbours)
        let distinctElementsActingLikeConnector = this.connectorDistinctNeighbours(elementActingLikeConnectorNeighbours)
        
        let neighbours = elementNeighbours.concat(overlayedElementNeighbours, distinctUnderlayedElements, elementActingLikeConnectorNeighbours)
        let distinctNeighbourElements = distinctElements.concat(distinctOverlayedElements, distinctUnderlayedElements, distinctElementsActingLikeConnector)

        if(distinctNeighbourElements.length <= 1) return null

        // Add connectingElement and connectingElementConnections attribute to connector
        connector = this.addElementsAndConnectionsToConnector(connector, neighbours, distinctNeighbourElements)

        // Check connecting elements and apply rule trigering attributes
        connector = this.addRuleTriggeringAttributes(connector)

        // once we have all the info let's define connectors and calculate spacers
        let defaultSideScrew = store.getters['api/getInventoryDefaultSideScrew'];
        let defaultConnectingPin = store.getters['api/getInventoryDefaultConnectorsConnectingPin'];

        // Add type to connector
        let connectingElementConnectionsLenght = connector.connectingElementConnections.length

        // Connector is not required as only 1 point is here 
        if(connectingElementConnectionsLenght == 1) return null

        // When we are deleting current element plays no role in color selection
        let color = this.calculateColorOfConnection(connector, true)

        // Normal elements
        if (connectingElementConnectionsLenght == 4) {
            connector.color = color;
            connector.item = defaultConnectingPin
        } else if (connectingElementConnectionsLenght == 2 && connector.connectingElementConnections.every(item => { return item.middleConnector })) {
            connector.color = color;
            connector.item = defaultConnectingPin
        } else if (connectingElementConnectionsLenght == 3 && connector.connectingElementConnections.some(item => { return item.middleConnector })) {
            connector.color = color;
            connector.item = defaultConnectingPin
        } else if(connectingElementConnectionsLenght > 1) {
            connector.color = 'black'
            connector.item = defaultSideScrew
        }    

        // Add spacers to connector
        this.addSpacersToconnector(connector)  
        return connector;
    }

    /**
     * 
     * @param {object} currentElement 
     * @returns {array}
     * 
     * Get all connectors and their types for the current element
     */
    calculateConnectors(currentElement) {
        // Connecting pin steel bars are placed directly on connecting pin so it has no connections
        if(this.helpers.isConnectingPinWithSteel(currentElement.item.rule)) return []
        
        let connectors = [];

        /**
         * Find all the points around the element where he can connect to other elements 
         * and augment them into a connector constant
         */
        let potentialConnectorPoints = this.findPotentialConnectingPoints(currentElement)

        for(let connector of potentialConnectorPoints) {


            let [elementNeighbours, overlayedElementNeighbours, underlayedElementNeighbours, elementActingLikeConnectorNeighbours] = this.getConnectorNeighbours(connector);
            
            /**
             * Disticns elements only (those whose gridPosition attribute match are the same element (store postion != grid position on element with the width or height grater than 1))
             * 
             * Also check for accesories acting like elements and add them to the grup
             */
            let distinctElements = this.connectorDistinctNeighbours(elementNeighbours)
            let distinctOverlayedElements = this.connectorDistinctNeighbours(overlayedElementNeighbours)
            let distinctUnderlayedElements = this.connectorDistinctNeighbours(underlayedElementNeighbours)
            let distinctElementsActingLikeConnector = this.connectorDistinctNeighbours(elementActingLikeConnectorNeighbours)
            
            let neighbours = elementNeighbours.concat(overlayedElementNeighbours, distinctUnderlayedElements, elementActingLikeConnectorNeighbours)
            let distinctNeighbourElements = distinctElements.concat(distinctOverlayedElements, distinctUnderlayedElements, distinctElementsActingLikeConnector)
            
            /**
             * All elements sourounding the connector are from the same source (double element middle position)
             * 
             * Exceptions are elements acting as accessories (cleats, fences) that can be placed on the edge hence they only have 1 neighbour
             * 
             * TODO - Make this check if its is the case of duble element midle position only to avoid making 10 excpetions down the line
             */
            if( distinctNeighbourElements.length <= 1 && 
                (!this.helpers.isOuterScrewJoint(currentElement.item.rule) && !this.helpers.isSpecialScrewJoint(currentElement.item.rule) && (!this.helpers.isFence(currentElement.item.rule)))
            ) continue;

            // Add connectingElement and connectingElementConnections attribute to connector
            connector = this.addElementsAndConnectionsToConnector(connector, neighbours, distinctNeighbourElements)


            
            // Check connecting elements and apply rule trigering attributes
            connector = this.addRuleTriggeringAttributes(connector)

            connectors.push(connector);
        }

        // When adding ladders we get undefined in the array so we clean the array
        connectors = connectors.filter((connector) => {
            return (connector != undefined)
        })

        // once we have all the info let's define connectors and calculate spacers
        let defaultSideScrew = store.getters['api/getInventoryDefaultSideScrew'];
        let defaultConnectingPin = store.getters['api/getInventoryDefaultConnectorsConnectingPin'];

        for (let connector of connectors) {
            // Chose the right connector depending on situation
            this.addConnectorTypeToConnector(connector, currentElement, defaultSideScrew, defaultConnectingPin)
            
            // Add spacers to connector
            this.addSpacersToconnector(connector)   
        }
        
        return connectors;
    }

    getItems() {
        //
        // USED FOR DEBUGGING
        //
        let elementCount = {};
        let distinct = {};

        for (let [key, element] of Object.entries({ ...this.elements})) {
            if (distinct[element.gridPosition.toString()]) continue
            else distinct[element.gridPosition.toString()] = key;

            if (elementCount[element.item.name + " " + element.variation.color] !== undefined) {
                elementCount[element.item.name + " " + element.variation.color] += 1;
            } else {
                elementCount[element.item.name + " " + element.variation.color] = 1;
            }
        }

        distinct = {};
        for (let [key, element] of Object.entries({ ...this.overlayedElements})) {
            if (distinct[element.gridPosition.toString()]) continue
            else distinct[element.gridPosition.toString()] = key;

            if (elementCount[element.item.name + " " + element.variation.color] !== undefined) {
                elementCount[element.item.name + " " + element.variation.color] += 1;
            } else {
                elementCount[element.item.name + " " + element.variation.color] = 1;
            }
        }

        distinct = {};
        for (let [key, element] of Object.entries({ ...this.underlayedElements})) {
            if (distinct[element.gridPosition.toString()]) continue
            else distinct[element.gridPosition.toString()] = key;

            if (elementCount[element.item.name + " " + element.variation.color] !== undefined) {
                elementCount[element.item.name + " " + element.variation.color] += 1;
            } else {
                elementCount[element.item.name + " " + element.variation.color] = 1;
            }
        }


        let connectorCount = {};
        let spacerCount = {"single": 0, "double": 0};
        for (let [, connector] of Object.entries(this.connectors)) {
            spacerCount["single"] += connector.spacers["single"];
            spacerCount["double"] += connector.spacers["double"];

            if (connector.placeholder) continue;
            if (connectorCount[connector.item.name + " " + connector.color] !== undefined) {
                connectorCount[connector.item.name + " " + connector.color] += 1;
            } else {
                connectorCount[connector.item.name + " " + connector.color] = 1;
            }
        }

        return {
            elements: elementCount,
            connectors: connectorCount,
            spacers: spacerCount
        }
    }

    getItemList() {
        let elementCount = {};
        let distinct = {};
        for (let [key, element] of Object.entries({ ...this.elements })) {
            if (distinct[element.gridPosition.toString()]) continue
            else distinct[element.gridPosition.toString()] = key;
            if (elementCount[element.variation.sku] !== undefined) {
                elementCount[element.variation.sku] += 1;
            } else {
                elementCount[element.variation.sku] = 1;
            }
        }

        distinct = {};
        for (let [key, element] of Object.entries({ ...this.overlayedElements })) {
            if (distinct[element.gridPosition.toString()]) continue
            else distinct[element.gridPosition.toString()] = key;
            if (elementCount[element.variation.sku] !== undefined) {
                elementCount[element.variation.sku] += 1;
            } else {
                elementCount[element.variation.sku] = 1;
            }
        }

        distinct = {};
        for (let [key, element] of Object.entries({ ...this.underlayedElements })) {
            if (distinct[element.gridPosition.toString()]) continue
            else distinct[element.gridPosition.toString()] = key;
            if (elementCount[element.variation.sku] !== undefined) {
                elementCount[element.variation.sku] += 1;
            } else {
                elementCount[element.variation.sku] = 1;
            }
        }

        // 205000 : single spacer 
        // 205100 : double spacer
        let connectorCount = {};
        let spacerCount = {205000: 0, 205100: 0};
        for (let [, connector] of Object.entries(this.connectors)) {
            spacerCount[205000] += connector.spacers["single"];
            spacerCount[205100] += connector.spacers["double"];
            if (connector.placeholder) continue;
            if (connectorCount[connector.item.sku[connector.color]] !== undefined) {
                connectorCount[connector.item.sku[connector.color]] += 1;
            } else {
                connectorCount[connector.item.sku[connector.color]] = 1;
            }
        }

        // Remove 0 spacer count if exist
        if(spacerCount[205000] == 0) delete spacerCount[205000]
        if(spacerCount[205100] == 0) delete spacerCount[205100]

        return {
            ...elementCount,
            ...connectorCount,
            ...spacerCount
        }
    }

    /**
     * @param {array} positions 
     * @returns   bool
     * 
     * Check if any of the points provided contain elements
     */
    checkForElements(positions) {
        return positions.some((position) => {
            return this.elements[position.toString()]
        })
    }

    /**
     * @param {object} position 
     * @param {array} connectingElements 
     * 
     * Check if any element in the array of connectin elements has a level zero, 
     * on the point they are connecting to
     */
     checkConnectionPointForLevelZero(position, connectingElements) {
        let connectionPointLevels = [];

        connectingElements.forEach((element) => {
            //Check every element available connections for the point we are connecting to and get its level
            element.availableConnections.forEach((availableConnection) => {
                if(element.gridPosition.move(availableConnection.position.x, availableConnection.position.y).toString() == position.toString()) connectionPointLevels.push(availableConnection.level) 
            })            
        })

        if(connectionPointLevels.find(level => level == 0) == undefined) return false

        return true
    }

    /**
     * 
     * @param {object} element 
     * @param {GridPoint} gridPosition 
     * @param {int} rotation 
     * @returns {object} element
     * 
     * Augmment element and strip it of unnecessary properties
     */
    augmentAndStripUnnecessaryValues(element, gridPosition, rotation) {
        element.gridPosition = gridPosition;

        if (!this.helpers.isBasicElement(element.item.rule) && (rotation == constants.ORIENTATION_TOP || rotation == constants.ORIENTATION_BOTTOM)) {
            element.size = constants.ELEMENT_SIZES[element.item.rule].rotate();
        } else {
            element.size = constants.ELEMENT_SIZES[element.item.rule];
        }
        
        if (element.item.availableConnections instanceof Array) {
            element.availableConnections = element.item.availableConnections;
        } else {
            element.availableConnections = element.item.availableConnections[rotation];
        }

        // Rember elements rotation (required for L shaped elements)
        element.rotation = rotation

        delete element.item.active
        delete element.item.created_at
        delete element.item.deleted_at
        //delete element.item.id
        delete element.item.updated_at
        //delete element.item.variations
        //delete element.item.availableConnections
        delete element.item.description
        
        delete element.variation.canvas_image
        delete element.variation.canvas_image_path
        delete element.variation.created_at
        delete element.variation.deleted_at
        delete element.variation.editor_color_picker_image
        delete element.variation.editor_color_picker_image_path
        delete element.variation.editor_image
        delete element.variation.editor_image_path
        //delete element.variation.id
        delete element.variation.product_id
        delete element.variation.updated_at
        
        return element
    }

    /**
     * 
     * @param {object} element 
     * @returns {array}
     * 
     * Find potential connecting point of element and fill them with connector element
     */
    findPotentialConnectingPoints(element) {
        return element.availableConnections.map((item) => {
            let pointPosition = element.gridPosition.move(item.position.x, item.position.y); 
            let connector = _.cloneDeep(constants.EMPTY_CONNECTOR)

            connector.gridPosition = pointPosition
            
            return connector;
        });
    }

    /**
     * 
     * @param {constants.EMPTY_CONNECTOR} connector 
     * 
     * Find points around connector and check for elements
     */
    getConnectorNeighbours(connector) {
        let elementNeighbours = [];
        let overlayedElementNeighbours = [];
        let underlayedElementNeighbours = [];
        let elementActingLikeConnectorNeighbours = [];

        for (let gridConnectorPoint of connector.gridPosition.getNeighbourSquaresAroundPoint()) {
            let element = this.getElement(gridConnectorPoint);
            let overlayedElement = this.getOverlayedElement(gridConnectorPoint);
            let underlayedElement = this.getUnderlayedElement(gridConnectorPoint);
            let elementActingLikeConnector = this.getConnector(gridConnectorPoint)
            
            if (element) {
                elementNeighbours.push(element);
            }

            if(overlayedElement) {
                overlayedElementNeighbours.push(overlayedElement)
            }

            if(underlayedElement) {
                underlayedElementNeighbours.push(underlayedElement)
            }

            if(elementActingLikeConnector && (this.helpers.isElementActingLikeConnector(elementActingLikeConnector.item.rule))) {
                // This has to be added manualy
                elementActingLikeConnector.availableConnections = constants.ELEMENT_CONNECTIONS[elementActingLikeConnector.item.rule];
                elementActingLikeConnectorNeighbours.push(elementActingLikeConnector)
            }
        }

        return [elementNeighbours, overlayedElementNeighbours, underlayedElementNeighbours, elementActingLikeConnectorNeighbours];
    }

    /**
     * 
     * @param {array} neighbours 
     * @returns {array}
     * 
     * Check the connector neighbours for distinct values (for exaple double element does not draw connectors between itself)
     */
    connectorDistinctNeighbours(neighbours) {
        return [...new Map(neighbours.map(element => [element.gridPosition, element])).values()];
    }

    /**
     * 
     * @param {constants.EMPTY_CONNECTOR} connector 
     * @param {array} neighbours 
     * @param {array} distinctNeighbourElements 
     * @returns {constants.EMPTY_CONNECTOR}
     * 
     * Add data regarding connecting elements to connector object 
     */
    addElementsAndConnectionsToConnector(connector, neighbours, distinctNeighbourElements) {
        let allConnectingElementConnections = [];

        for (let neighbour of neighbours) {
            // find connectingElementConnections and save them
            let connectingElementConnections = neighbour.availableConnections.filter((connection) => { 
                connection.gridPosition = neighbour.gridPosition.move(connection.position.x, connection.position.y)
                return neighbour.gridPosition.move(connection.position.x, connection.position.y).toString() == connector.gridPosition.toString();
            });
            allConnectingElementConnections.push(...connectingElementConnections);
        }
        
        let distinctConnectingElementConnections = [...new Map(allConnectingElementConnections.map(connection => [connection.gridPosition, connection])).values()];
        connector.connectingElementConnections = distinctConnectingElementConnections
        connector.connectingElements = distinctNeighbourElements;

        return connector
    }

    addRuleTriggeringAttributes(connector) {
        connector.connectingElements.forEach(element => {
            /**
             * Only affect connector that acutaly has a cross section with the neighbour element (store point != connection point)
             * for exaple a fence has 3 store points but only 2 connection points so it might be the neighbour of the element but they have 0 overlaping 
             * connecting points
             */
            element.availableConnections.forEach(connectingPoint => {
                let connectingPointRelativeGridPosition = element.gridPosition.move(connectingPoint.position.x, connectingPoint.position.y)
                if(connectingPointRelativeGridPosition.toString() == connector.gridPosition.toString()) {
                    if (this.helpers.isSideBumper(element.item.rule) || this.helpers.isSideBumperCornerOuter(element.item.rule) || this.helpers.isSideBumperCornerInner(element.item.rule)) {
                        connector.hasSidebumper = true
                    }
        
                    if (this.helpers.isElementActingLikeConnector(element.item.rule)) {
                        connector.isAccessory = true
                    }
        
                    // Mooring devices - If connector has mooring device just note it and return the current connector (does not change the connector)
                    if(this.helpers.isMooringDevice(element.item.rule)) {
                        connector.hasMooring = true
                    }
        
                    // Ladders, bathing slides, fences, and pile cages elements are placeholders
                    if (this.helpers.isLadder(element.item.rule) || this.helpers.isBathingSlide(element.item.rule) || this.helpers.isFence(element.item.rule) || this.helpers.isPileCageOuter(element.item.rule) || this.helpers.isPileCageInner(element.item.rule)) {
                        connector.placeholder = true;
                    }
                }
            })   
        })

        return connector
    }

    /**
     * 
     * @param {constants.EMPTY_CONNECTOR} connector 
     * @param {object} currentElement 
     * @param {object} defaultSideScrew 
     * @param {object} defaultConnectingPin 
     * @returns {constants.EMPTY_CONNECTOR} 
     * 
     * Add type (product) and color to connector 
     */
    addConnectorTypeToConnector(connector, currentElement, defaultSideScrew, defaultConnectingPin) {
        let color = currentElement.variation.color
        
        /**
         * Edge cases
         */
        // Connector is actualy a accessory, we do not add default connecting pin or side screw (only happens when adding)
        if (connector.isAccessory || connector.placeholder) {
            let item = this.productToItemInConnectors(currentElement)
            connector.color = color;
            connector.item = item

            connector.item.sku = currentElement.item.variations.reduce((acc, item)=>{
                acc[item.color] = item.sku;
                return acc;
            }, {})

            return connector
        }

        // Connector already exists so do not change him (only happens when deleting) or placing a mooring device
        if(this.getConnector(connector.gridPosition)) {
            let existingConnector = this.getConnector(connector.gridPosition)

            // Some connectors do not have items (placeholders)
            if (existingConnector.item && this.helpers.isOuterScrewJoint(existingConnector.item.rule)) {
                connector.color = existingConnector.color;
                connector.item = existingConnector.item
                connector.isAccessory = true

                return connector
            }
        }

        
        // Sidebumper always add default sidescrews
        if (connector.hasSidebumper) {
            connector.color = 'black';
            connector.item = defaultSideScrew

            return connector
        }
        
        
        let connectingElementConnectionsLenght = connector.connectingElementConnections.length

        //If it has mooring get the current connector color & item and place it
        if (connector.hasMooring) {
            let existingConnector = _.cloneDeep(this.getConnector(connector.gridPosition))

            connector.color = existingConnector.color;
            connector.item = existingConnector.item
            
            return connector
        }
        
        // Normal elements
        if (connectingElementConnectionsLenght == 4) {
            connector.color = color;
            connector.item = defaultConnectingPin
        } else if (connectingElementConnectionsLenght == 2 && connector.connectingElementConnections.every(item => { return item.middleConnector })) {
            connector.color = color;
            connector.item = defaultConnectingPin
        } else if (connectingElementConnectionsLenght == 3 && connector.connectingElementConnections.some(item => { return item.middleConnector })) {
            connector.color = color;
            connector.item = defaultConnectingPin
        } else if(connectingElementConnectionsLenght > 1) {
            connector.color = 'black'
            connector.item = defaultSideScrew
        }

        return connector
    }

    calculateColorOfConnection(connector) {
        // Check connector connectingElements and pick the most frequent color
        let colors = {};
        connector.connectingElements.forEach(element => {
            // Skip element acting like connector when calculating color when deleting
            if(element.item.type != "basic_element") return;
            if(colors[element.variation.color] == undefined) {
                colors[element.variation.color] = 1
            } else {
                colors[element.variation.color]++
            }
        })

        let color = "black" // Set default value in case of problems and pray
        let bigestNum = 0;
        for (let [colorKey, element] of Object.entries({ ...colors})) {
            if(element > bigestNum) {
                bigestNum = element
                color = colorKey
            }
        }
        
        return color
    }

    productToItemInConnectors(currentElement) {
        let item = _.cloneDeep(constants.EMPTY_ITEM_IN_CONNECTION)
        item.connections = constants.ELEMENT_CONNECTIONS[currentElement.rule]

        item.id = currentElement.item.id
        item.name = currentElement.item.name
        item.rule = currentElement.item.rule
        item.size = currentElement.item.size
        item.type = currentElement.item.type
        item.sku = [].push({[currentElement.variation.color] : currentElement.variation.sku})
        // TOOD make this more fitting with store API and make 1 functions for all 
        /*item.sku = currentElement.variation.reduce((acc, item)=>{
            acc[item.color] = item.sku;
            return acc;
        }, {})*/

        return item;
    }

    /**
     * 
     * @param {constants.EMPTY_CONNECTOR} connector 
     * 
     * Add spacers object to connecotr object
     */
    addSpacersToconnector(connector) {
        let spacers = {
            single: 0, 
            double: 0
        };

        /**
         * Connection contains level 99 means it removes the pin on position and replaces it but does not affect spacer count
         * so remove the lvl 99 at this point - Ladders only
         */
        let connectingElementConnections = _.cloneDeep(connector.connectingElementConnections)
        let connectingElementConnectionsWithout99 = connectingElementConnections.filter((item) => item.level != 99)
        connector.connectingElementConnections = connectingElementConnectionsWithout99


        /**
         * Connection contains level zero means it must level it self (fill all available positions with spacers)
         */
        if( this.checkConnectionPointForLevelZero(connector.gridPosition, connector.connectingElements) ||
            this.helpers.isOuterScrewJoint(connector.item.rule) ||
            this.helpers.isSpecialScrewJoint(connector.item.rule) ||
            this.helpers.isInnerScrewJoint(connector.item.rule) ||
            this.helpers.isFence(connector.item.rule)
        ) {
            // get all "missing" levels
            let sortedLevels = connector.connectingElementConnections.filter((item) => item.level != 0).map((item) => item.level).sort();
            let missingLevels = [];
            for (let i = 1; i<=4; i++) {
                if (!sortedLevels.includes(i)) {
                    missingLevels.push(i);
                }
            }

            // calculate spacers
            for (let i = 0; i<missingLevels.length; i++){
                if (i + 1 < missingLevels.length) {
                    let currLevel = missingLevels[i];
                    let nextLevel = missingLevels[i+1];
                    if (nextLevel - currLevel == 1) { // if difference between missing levels is 1 it means they are "together" which means double is ok
                        spacers.double += 1;
                        i += 1; // once you put double skip next level because it already belongs to a double
                        continue;
                    }
                }
                spacers.single += 1;
            }
        } else {
            let sortedLevels = connector.connectingElementConnections.map((item) => item.level).sort();
            for (let i = 0; i<sortedLevels.length-1; i++){
                let diff = sortedLevels[i+1] - sortedLevels[i] - 1;
                if (diff < 0) console.log("WARNING - connections are clashing");
                if (diff == 2) {
                    spacers.double += 1;
                } else if (diff == 1) {
                    spacers.single += 1;
                }  
            }
        }
        connector.spacers = spacers;
    }

    /**
     * 
     * @param {GridPoint} element 
     * @returns {array} 
     * 
     * Find all distinct elements around position 
     */
    getPositionDistinctNeighbours(gridPosition) {
        let neighbours = []
        
        gridPosition.getNeighbourPoints().forEach(neighbourPoint => {
            let neigbhour = this.getElement(neighbourPoint)
            if (neigbhour) {
                neighbours.push(neigbhour)
            }
        })

        return [...new Map(neighbours.map(element => [element.gridPosition, element])).values()];
    }

    /**
     * 
     * @param {GridPoint} element 
     * @returns {array} 
     * 
     * Find all distinct elements around position but return only those who have overlaping connection points
     */
     getPositionDistinctNeighboursWithOverlapingConnectionPoints(gridPosition) {
        let overlapingNeighbours = [];
        let neighbours = this.getPositionDistinctNeighbours(gridPosition)

        neighbours.forEach(neigbhour => {
            neigbhour.availableConnections.forEach(connection => {
                let neighbourConnectingPoint = neigbhour.gridPosition.move(connection.position.x, connection.position.y)
                
                if(neighbourConnectingPoint.toString() == gridPosition.toString()) overlapingNeighbours.push(neigbhour)
            })
        })

        return overlapingNeighbours
    }

    /**
     * 
     * @param {GridPoint} element 
     * @returns {array} 
     * 
     * Find all elements around position 
     */
     getPositionNeighbours(gridPosition) {
        let neighbours = []
        
        gridPosition.getNeighbourPoints().forEach(neighbourPoint => {
            let neigbhour = this.getElement(neighbourPoint)
            if (neigbhour) {
                neighbours.push(neigbhour)
            }
        })

        return neighbours
    }

    /**
     * 
     * @param {GridPoint} element 
     * @returns {array} 
     * 
     * Find all elements around position but return only those who have overlaping connection points
     */
     getPositionNeighboursWithOverlapingConnectionPoints(gridPosition) {
        let overlapingNeighbours = [];
        let neighbours = this.getPositionNeighbours(gridPosition)

        neighbours.forEach(neigbhour => {
            neigbhour.availableConnections.forEach(connection => {
                let neighbourConnectingPoint = neigbhour.gridPosition.move(connection.position.x, connection.position.y)
                
                if(neighbourConnectingPoint.toString() == gridPosition.toString()) overlapingNeighbours.push(neigbhour)
            })
        })

        return overlapingNeighbours
    }
}
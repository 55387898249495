<template>
  <v-dialog
    v-model="accessoriesDialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >  
    <v-card class="dialog-accessories">
      <v-toolbar
        dark
        color="#004996"
        class="elements-dialog-toolbar"
      >
        <v-btn
          icon
          dark
          @click="accessoriesDialog = !accessoriesDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          {{ $t(`configurator.accessories.title`) }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            rounded
            color="green"
            dark
            @click="accessoriesDialog = !accessoriesDialog"
          >
            {{ $t(`configurator.accessories.use`) }}
            <v-icon>mdi-arrow-right-bold</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div class="accessories-cards-container">
        <div>
        <v-alert
            dense
            border="left"
            type="warning"
          >
            {{ $t(`configurator.accessories.alert`) }}
          </v-alert>
        </div>
        <v-expansion-panels>
          <v-expansion-panel
            v-for="(accessoryGroup, index) in accessoriesByGroups"
            :key="index"
          >
            <v-expansion-panel-header>
              {{ $t(`configurator.accessories.groups.${index}`) }}
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <v-row>
                <accessory-card
                  v-for="accessory in accessoryGroup"
                  :key="accessory.id"
                  :item="accessory"
                  v-on:accessory-changed="$emit('accessory-changed')"
                ></accessory-card>
              </v-row>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import AccessoryCard from "./AccessoryCard.vue"

export default {
  name: 'DialogAccessories',

  components: {
    AccessoryCard
  },

  data () {
    return {
    }
  },

  computed: {
    accessoriesDialog: {
      get() {
        return this.$store.getters['configurator/getAccessoriesDialog'];
      },
      set(newValue) {
        this.$store.commit('configurator/setAccessoriesDialog', newValue)
      }
    },

    accessoriesByGroups: {
      get() {
        return this.$store.getters['api/getAccessoriesAndConnectorsByGroups'];
      },
    }

  },
}
</script>

<template>
  <v-dialog
    v-model="primaryItemsDialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >  
    <v-card class="dialog-primary-items">
      <v-toolbar
        dark
        color="#004996"
        class="elements-dialog-toolbar"
      >
        <v-btn
          icon
          dark
          @click="primaryItemsDialog = !primaryItemsDialog"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          {{ $t(`configurator.primaryItems.title`) }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn
            rounded
            color="green"
            dark
            @click="primaryItemsDialog = !primaryItemsDialog"
          >
            {{ $t(`configurator.primaryItems.use`) }}
            <v-icon>mdi-arrow-right-bold</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <div class="primary-cards-container">
        <v-row>
          <primary-item-card
            v-for="primaryItem in primaryItems"
            :key="primaryItem.id"
            :item="primaryItem"
            v-on:primary-element-changed="$emit('primary-element-changed')"
          ></primary-item-card>
        </v-row>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import PrimaryItemCard from "./PrimaryItemCard.vue"

export default {
  name: 'DialogPrimaryItems',

  components: {
    PrimaryItemCard
  },

  data () {
    return {
    }
  },

  computed: {
    primaryItemsDialog: {
      get() {
        return this.$store.getters['configurator/getPrimaryItemsDialog'];
      },
      set(newValue) {
        this.$store.commit('configurator/setPrimaryItemsDialog', newValue)
      }
    },

    primaryItems: {
      get() {
        return this.$store.getters['api/getPrimaryItems'];
      },
    }

  },
}
</script>

<template>
  <div class="level-picker">
    <div class="level-picker-container">
      <div :class="['level', (selectedLevel == 0 ? 'selected' : '')]" @click="selectedLevel = 0">
        {{ $t(`configurator.levels.l-2`) }}
      </div>
      <div :class="['level', (selectedLevel == 1 ? 'selected' : '')]" @click="selectedLevel = 1">
        {{ $t(`configurator.levels.l-1`) }}
      </div>
      <div :class="['level', (selectedLevel == 2 ? 'selected' : '')]" @click="selectedLevel = 2">
        {{ $t(`configurator.levels.l0`) }}
      </div>
      <div :class="['level', (selectedLevel == 3 ? 'selected' : '')]" @click="selectedLevel = 3">
        {{ $t(`configurator.levels.l1`) }}
      </div>
      <div :class="['level', (selectedLevel == 4 ? 'selected' : '')]" @click="selectedLevel = 4">
        {{ $t(`configurator.levels.l2`) }}
      </div>   
    </div>
  </div>
</template>

<script>
export default {
  name: 'LevelPicker',
  data () {
    return {
    }
  },

  computed: {
    selectedLevel: {
      get() {
        return this.$store.getters['configurator/getLevel'];
      },
      set(newValue) {
        this.$store.commit('configurator/setLevel', newValue)
      }
    },

  },
}
</script>

import GridPoint from "@/components/configurator/Editor/lib/Cordinates/GridPoint";

export default class TextElement {
    constructor(gridPosition, text, id, childOf = null) {

        if(!(gridPosition instanceof GridPoint)) throw 'gridPosition must be instance of GridPoint!'

        this.gridPosition = gridPosition;
        this.text = text;
        this.id = id; // Id is used as name parameter for PIXI.Text so its easy traceable

        this.childOf = childOf;
        
    }

    getParent() {
      return this.childOf;  
    }

    isChild() {
        return (this.childOf != null)
    }

    getId() {
        return this.id;
    }
}

import GridPoint from "@/components/configurator/Editor/lib/Cordinates/GridPoint";
import TextElement from "@/components/configurator/Editor/Element/TextElement";

export default class TextStore { 
    /**
     * Text will hold 2 level object (XxY-Level) for text on that coordinate and then each text has unique ID assigned to it so its easyer
     * for children to find their parents
     * {
     *   '0x1-0' : {
     *        '12345678' : TextElement Object
     *        '12345679' : TextElement Object
     *      }
     *   '0x2-0' : {
     *        '12345678' : TextElement Object
     *      }
     * }
     * So when user click on the coordinate we can simply get all the texts placed on it either by position or chilOf attribute
     */
    texts = {};

    storeText(gridPosition, text, width, boxSize) {
        if(!(gridPosition instanceof GridPoint)) throw 'gridPosition must be instance of GridPoint!'

        let numberOfExtraBoxesTextUsed = Math.floor(width / boxSize);
        let childGridPosition = gridPosition; // Child position must update each iteration (if they exist)
        let parentTextElementId = this.createElementId();
        let parentTextElement = new TextElement(gridPosition, text, parentTextElementId);

        // Store parent element first
        this.#store(gridPosition, parentTextElement);

        // If text overflows into other grid parts create child instances of this text there (text moves only horizontaly so insert only via X axis)
        for (let x = 1; x <= numberOfExtraBoxesTextUsed; x++) {
            childGridPosition = childGridPosition.move(1, 0); // Create a new instace for child postion & increase position of the child element
            let childTextElement = new TextElement(childGridPosition, text, parentTextElementId, parentTextElement); // Create child TextElement instance
            this.#store(childGridPosition, childTextElement);
        }
        // Return ID of parent to be used in pixi for name attribute when creatin PIXI.Text
        return parentTextElementId;

    }
    /**
     * Repetitive store code (private function)
     * 
     * When storing, child ID is inherited from parent so we can easy search for parent in different groups
     */
    #store(gridPosition, textElement) {
        let textGroupId = this.createElementsGroupId(gridPosition);

        if(!this.texts[textGroupId]) this.texts[textGroupId] = {}; // Better way of checking nested keys dont exist ? 
        this.texts[textGroupId][textElement.getId()] = textElement;
    }

    removeText(gridPosition) {
        if(!(gridPosition instanceof GridPoint)) throw 'gridPosition must be instance of GridPoint!'

        let textsOnPosition = this.getTextsOnPosition(gridPosition);

        if(!textsOnPosition) return [] // No texts found on position return empty array

        let idsOfParentsToRemove = []; // All parents will be removed and all their children

        // Get all IDs from clicked location if element is a child get his parent ID
        for (const textId in textsOnPosition) {
            let id = (textsOnPosition[textId].isChild() ? textsOnPosition[textId].getParent().getId() : textsOnPosition[textId].getId());
            idsOfParentsToRemove.push(id);
        }
        // Iterate over all texts and in each group remove all selected IDs
        for (const group in this.texts) {
            idsOfParentsToRemove.forEach((id) => {
                delete this.texts[group][id]
            })
        } 
        return idsOfParentsToRemove;
    }

    removeAllTexts() {
        this.texts = {};
    }

    getTextsOnPosition(gridPosition) {
        return this.texts[gridPosition.toString()];
    }

    getAll() { return this.texts; }

    createElementId() {
        return Math.random();
    }

    // Creates id for element group
    createElementsGroupId(gridPosition) {
        return gridPosition.toString();
    }
}


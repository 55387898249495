<template>
  <v-col cols="12" md="6">
    <div class="item primary-item">

      <v-card
        :class="['mx-auto item-card', (isThisItemSelected ? 'selected' : '')]"
        max-width="400"
        @click="updatePrimary()"
      >
        <v-img
          class="white--text align-end"
          height="200px"
          :src="localVariation.editor_image_path"
        ></v-img>
        <div class="primary-item-description">
            {{ item.description }}
        </div>
        <v-card-actions class="variation-color-picker">
          <div
            v-for="variation in item.variations"
            :key="variation.id"
            :class="['variation-color', variation.color, (variation.color == localVariation.color ? 'selected' : '')]" 
            @click="updateVariation(variation); localSelectedVariation = variation"
          ></div>
        </v-card-actions>
        <hr>
        <div class="selected-item-controlls" v-if="isThisItemSelected">
          <v-btn block @click="primaryItemsDialog = !primaryItemsDialog" class="use-primary-button">
            {{ $t(`configurator.accessories.use`) }}
          </v-btn>
        </div>
      </v-card>
    </div>
  </v-col>
</template>

<script>
export default {
  name: 'PrimaryItemCard',

  props: {
    item: {
      type: Object,
      reqired: true,
    }
  },

  data () {
    return {
      localSelectedVariation: null,
    }
  },

  computed: {
    localVariation: {
      get() {
        //if this is the item user is curenlty using return his variation
        if(this.isThisItemSelected) return this.selectedVariation
        
        //remember last known state of color picker for this item
        if(this.localSelectedVariation != null) return this.localSelectedVariation
        
        //onload if item is not selected set the first value
        return this.item.variations[0]
      },
      set(newValue) {
        return newValue
      }
    },
    selectedItem: {
      get() {
        return this.$store.getters['configurator/getPrimaryItem']
      },
      set(newValue) {
        this.$store.commit('configurator/setPrimaryItem', newValue) //store local item to store
      }
    },
    selectedVariation: {
      get() {
        return this.$store.getters['configurator/getPrimaryItemVariation']
      },
      set(newValue) {
        this.$store.commit('configurator/setPrimaryItemVariation', newValue)
      }
    },
    isThisItemSelected() {
      return (this.selectedItem.id == this.item.id)
    },
    primaryItemsDialog: {
      get() {
        return this.$store.getters['configurator/getPrimaryItemsDialog'];
      },
      set(newValue) {
        this.$store.commit('configurator/setPrimaryItemsDialog', newValue)
      }
    },
  },

  methods: {
    updatePrimary() {
      //when primary item (block) is clicked update it globaly and set his local variation as selected one
      this.selectedVariation = this.localVariation //this must go before item update becouse item update trigers isThisItemSelected in turn trigering localVariation
      this.selectedItem = this.item
      this.$emit('primary-element-changed')
      
    },
    updateVariation(variation) {
      //when variation (color) is clicked update variation globaly and localy
      this.selectedVariation = variation
      this.localVariation = variation
      this.$emit('primary-element-changed')
    },
  }
}
</script>
